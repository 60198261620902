.payment-spinner {
    margin-top: 2px;
    padding-left: 15px;
}
.card-error {
    font-size: 13px;
    color: #eb1c26;
}
.card-success {
    font-size: 13px;
    color: green;
}
.hidden {
    dispaly: none;
}
.get-order-detail-loading {

}
