.cell-order-id {
    min-width: 140px;
}
.learn-more-text {
    font-size: 11px;
    color: #516F90;
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
}
.learn-more-link {
    font-size: 11px;
    font-weight: bold;
    color: #3DB5E6;
    text-decoration:none;
}
.order-popup-text-message {
    /*font-family: Nunito;*/
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
}
.hide {
    display: none;
}
.show {
    display: block;
}
.order-id-text {
    font-size: 15px;
    font-weight: bold;
    color: grey;
    /*color: #516F90;*/
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
}
