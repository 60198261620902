.button {
    background-color: #3DB5E6;
    box-shadow: 0 0px 14px rgba(53, 64, 82, 0.05), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 7px 14px;
}
.button:hover {
    background-color: #36a2cf;
}
.button-label {
    color: #fff;
}
.download-button {
    background-color: #3DB5E6;
    box-shadow: 0 0px 14px rgba(53, 64, 82, 0.05), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 7px 14px;
    width: 160px;
}
.download-button-label {
    color: #fff;
}
.download-button:hover {
    background-color: #0eabbd;
}
.download-button-label {
    color: #fff;
}
.paynow-button {
    background-color: #3DB5E6;
    box-shadow: 0 0px 14px rgba(53, 64, 82, 0.05), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 7px 14px;
    width: 160px;
}
.paynow-button-label {
    color: #fff;
}
.paynow-button:hover {
    background-color: #0eabbd;
}
.paynow-button-label {
    color: #fff;
}
.link-text {
    color: #3DB5E6;
}
.s0 {
    padding-top: 0px;
}
.s5 {
    padding-top: 5px;
}
.s10 {
    padding-top: 10px;
}
.s15 {
    padding-top: 15px;
}
.s20 {
    padding-top: 20px;
}
.s25 {
    padding-top: 25px;
}
.s30 {
    padding-top: 30px;
}
.s35 {
    padding-top: 35px;
}
.s40 {
    padding-top: 40px;
}
.s45 {
    padding-top: 45px;
}
.s50 {
    padding-top: 50px;
}
.s60 {
    padding-top: 60px;
}
.s70 {
    padding-top: 70px;
}
.s80 {
    padding-top: 80px;
}
.s90 {
    padding-top: 90px;
}
.s100 {
    padding-top: 100px;
}
.auth-padding {
    padding-left: 210px;
    padding-right: 210px;
}
.download-pdf {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.progress {
    padding-left: 10px;
}
.center_container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-name-text {
    font-size: 13px;
    /*color: #516F90;*/
    color: black;
}
