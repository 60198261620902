.titleBox {
    justify-content: center;
    align-items: center;
}

.title {
    /*position: absolute;
    left: 931px;
    top: 406px;*/
    color: black;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    width: 318px;
    height: 39px;
}

.subtitle {
    /*position: absolute;*/
    color: black;
    font-family: normal;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    width: 472px;
    height: 18px;
    left: 855px;
    top: 466px;
}

.statusImg {
    width: 200px;
    height: 10px;
}

.no-data-container {
    text-align: center;
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
}

.no-data-text {
    font-size: 12px;
    font-style: normal;
    /*font-weight: 700;*/
    line-height: 20px;
}

.radon-circle-img {
    width: 10px;
}

.cell-radon-number {
    min-width: 140px;
}

.cell-client {
    min-width: 150px;
}

.radon-number-after-img {
    padding-left: 10px;
}

.card-label {
    fontSize: 9px;
    /*fontWeight: bold;*/
    fontStyle: normal;
    lineHeight: 30px;
    color: #000000;
    opacity: 0.5;
}

.card-label .span {
    fontSize: 9px;
}

.common-card {
    min-height: 800px;
}

.loading-card {
    min-height: 610px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-card-dashboard-chart {
    min-height: 455px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-char-no-data {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

/* New UX */
.activation-select {
    text-align: left;
    width: 100%;
}

/* New UX */
.activation-menuitem {
    text-align: left;
    background: #dddddd;
}

.issue-type-select {
    min-width: 120px;
    text-align: center;
}

.issue-type-menuitem {
    min-width: 240px;
    text-align: center;
}

.state-select {
    min-width: 120px;
    text-align: center;
}

.state-select-menuitem {
    min-width: 240px;
    text-align: center;
}

.running-mode-select {
    width: 100%;
    text-align: left;
}

.running-mode-menuitem {
    text-align: left;
    background: #dddddd;
}

.dropdown-select {
    width: 100%;
    text-align: left;
}

.dropdown-menuitem {
    padding-left: 25px;
    text-align: left;
    background: #dddddd;
}

/* Not used */
.dropdown-menuitem-selected {
    padding-left: 25px;
    text-align: left;
    background: darkred;
}

.save-changes-spinner {
    margin-top: 2px;
    padding-left: 15px;
}

