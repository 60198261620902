.popup-text-message {
    /*font-family: Nunito;*/
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
.cell-invoice-id {
    min-width: 130px;
}
.cell-issue-date {
    min-width: 130px;
}
.cell-description {
    min-width: 190px;
}
.settings-changes-spinner {
    margin-top: 2px;
    padding-left: 15px;
}
