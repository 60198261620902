.line-chart-container {
    /*min-height: 100vh; !* height of the browser viewport *!*/
    display: flex;
    justify-content: center;
    align-items: center;
}
.line-chart-root {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.zoom-button {
    /*background-color: #3DB5E6;*/
    /*box-shadow: 0 0px 14px rgba(53, 64, 82, 0.05), 0 1px 3px rgba(0, 0, 0, 0.08);*/
    padding: 7px 14px;
}
.zoom-button-label {
    /*color: #fff;*/
    color: grey;
}
.zoom-button:hover {
    /*background-color: #0eabbd;*/
}

